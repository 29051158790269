import { createContext, useEffect, useRef, useState } from 'react'
import type { ReactNode } from 'react'

export const PortalContainerContext = createContext<HTMLDivElement | null>(null)

const PortalContainerProvider = ({ children }: { children: ReactNode }) => {
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(
    null,
  )
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setPortalContainer(containerRef.current)
  }, [containerRef])

  return (
    <>
      <PortalContainerContext.Provider value={portalContainer}>
        {children}
      </PortalContainerContext.Provider>
      <div className="z-[99]" ref={containerRef}></div>
    </>
  )
}

export default PortalContainerProvider
