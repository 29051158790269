/** Async promise that waits x amount in milleseconds */
export const wait = (dt: number) => new Promise((res) => setTimeout(res, dt))

/**
 * Call an assertion function n times with a optional delay in between.
 *
 * @param config.tries a number bigger than 0
 * @param config.delay a positive number (milleseconds)
 * @param config.assertFn a function that returns a boolean for the assertion result, can be a promise
 * @returns a boolean informing if the assertion was successful or not
 */
export const tryUntilTrue = async ({
  tries,
  delay = 0,
  assertFn,
}: {
  tries: number
  delay?: number
  assertFn: () => Promise<boolean> | boolean
}): Promise<boolean> => {
  if (tries <= 0) throw new Error('"tries" must be bigger than 0')

  if (delay < 0) throw new Error('"delay" must be a positive value')

  // Tries until assertion is true or max tries is reached.
  for (let t = tries; t > 0; t--) {
    const assertion = await assertFn()

    if (assertion) return true

    await wait(delay)
  }

  return false
}
