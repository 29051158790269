import posthog from 'posthog-js'
import { useEffect, useState } from 'react'

import { tryUntilTrue } from '~/utils/async.utils'

export default function usePosthogInitialized() {
  const [init, setInit] = useState(false)

  useEffect(() => {
    tryUntilTrue({
      tries: 10,
      delay: 250,
      assertFn: () => !!posthog.__loaded,
    }).then(() => setInit(posthog.__loaded))
  }, [])

  return init
}
